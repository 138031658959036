<template>
  <div>
    <b-card
      v-if="organizations.length !== 0"
      class="mt-1"
    >
      <b-card-header>
        <b-card-title>
          {{ $t('organizationManagement') }}
        </b-card-title>
        <b-card-sub-title>
          {{ $t('organizationsCan') }} <b>{{ $t('not') }}</b> {{ $t('beDeleted') }}
        </b-card-sub-title>
      </b-card-header>
      <b-card-body>
        <vue-good-table
          :rows="organizations"
          :columns="organizationColumns"
          :select-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: false,
            perPage:3
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <div
              v-if="props.column.field === 'edit'"
            >
              <b-button-group style="width: 100%">
                <b-button
                  v-b-modal.add-organization-modal
                  variant="primary"
                  class="btn-icon"
                  @click="selectedOrganization = props.row"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
              </b-button-group>
            </div>
          </template>
        </vue-good-table>
        <b-button
          v-b-modal.add-organization-modal
          class="mt-1"
          block
          variant="outline-primary"
          @click="selectedOrganization = undefined"
        >
          {{ $t('addOrganization') }}
          <feather-icon icon="UserPlusIcon" />
        </b-button>
      </b-card-body>
    </b-card>
    <add-organization-modal
      :organization="selectedOrganization"
      @organisationsUpdated="getOrganizations"
    />
  </div>
</template>

<script>

import { VueGoodTable } from 'vue-good-table'
import {
  BButton, BButtonGroup, BCard, BCardBody, BCardHeader, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import AddOrganizationModal from '@/components/forms/OrganizationModal.vue'

export default {
  name: 'OrganizationManagement',
  components: {
    VueGoodTable, BButton, BButtonGroup, BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardBody, AddOrganizationModal,
  },
  data() {
    return {
      organizations: [],
      selectedOrganization: undefined,
      organizationColumns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'VAT',
          field: 'vat',
        },
        {
          label: 'Adresse',
          field: 'address',
        },
        {
          label: 'Stadt',
          field: 'city',
        },
        {
          label: 'PLZ',
          field: 'postalcode',
        },
        {
          label: 'Land',
          field: 'country',
        },
        {
          label: 'Bearbeiten',
          field: 'edit',
          sortable: false,
        },
      ],
    }
  },
  computed: {
  },
  async mounted() {
    await this.getOrganizations()
  },
  methods: {
    async getOrganizations() {
      const organizationsResponse = await this.$http.get('/organizations')
      this.organizations = organizationsResponse.data
    },
    async handleDelete() {
      console.log('Deleting', this.selectedOrganization)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
