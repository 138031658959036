<template>
  <b-modal
    id="add-organization-modal"
    :no-close-on-backdrop="true"
    cancel-variant="outline-secondary"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    centered
    :title="(id) ? 'Organisation bearbeiten' : 'Organisation hinzufügen'"
    @hidden="resetModal"
    @show="loadData"
    @ok="handleSubmit"
  >
    <b-form>
      <b-form-group>
        <label for="name"> <h6>{{ $t('name') }}</h6> </label>
        <b-form-input
          id="name"
          v-model="name"
          :state="nameValid"
          class="mb-1"
        />
        <label for="email"> <h6>{{ $t('email') }}</h6> </label>
        <b-form-input
          id="email"
          v-model="email"
          :state="emailValid"
          class="mb-1"
        />
        <label for="name"> <h6>{{ $t('vat') }}</h6> </label>
        <b-form-input
          id="vat"
          v-model="vat"
          class="mb-1"
        />
        <label for="address"> <h6>{{ $t('address') }}</h6> </label>
        <b-form-input
          id="address"
          v-model="address"
          class="mb-1"
        />
        <label for="city"> <h6>{{ $t('city') }}</h6> </label>
        <b-form-input
          id="city"
          v-model="city"
          class="mb-1"
        />
        <label for="postalcode"> <h6>{{ $t('postleitzahl') }}</h6> </label>
        <b-form-input
          id="postalcode"
          v-model="postalcode"
          class="mb-1"
        />
        <label for="country"> <h6>{{ $t('country') }}</h6> </label>
        <b-form-input
          id="country"
          v-model="country"
          class="mb-1"
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AddOrganizationModal',
  components: {
    BFormInput,
    BFormGroup,
    BForm,
  },
  props: {
    organization: {
      type: Object,
      default: () => ({
        id: undefined,
        name: '',
        email: '',
        vat: '',
        address: '',
        city: '',
        postalcode: '',
        country: '',
      }),
    },
  },
  data() {
    return {
      id: undefined,
      name: '',
      email: '',
      vat: '',
      address: '',
      city: '',
      postalcode: '',
      country: '',
      submitPressed: false,
    }
  },
  computed: {
    newOrganization() {
      const newOrg = {
        name: this.name,
        email: this.email,
        vat: this.vat,
        address: this.address,
        city: this.city,
        postalcode: this.postalcode,
        country: this.country,
      }
      if (this.id) {
        newOrg.id = this.id
      }
      return newOrg
    },
    nameValid() {
      if (this.submitPressed) {
        if (this.newOrganization.name !== '') {
          return true
        }
        if (this.newOrganization.name === '') {
          return false
        }
      }
      return undefined
    },
    emailValid() {
      if (this.submitPressed) {
        if (this.newOrganization.email !== '') {
          return true
        }
        if (this.newOrganization.email === '') {
          return false
        }
      }
      return undefined
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    resetModal() {
      this.id = undefined
      this.name = ''
      this.email = ''
      this.vat = ''
      this.address = ''
      this.city = ''
      this.postalcode = ''
      this.country = ''
    },
    loadData() {
      console.log(this.organization)
      if (this.organization.id) {
        this.id = this.organization.id
      }
      this.name = this.organization.name
      this.email = this.organization.email
      this.vat = this.organization.vat
      this.address = this.organization.address
      this.city = this.organization.city
      this.postalcode = this.organization.postalcode
      this.country = this.organization.country
      this.submitPressed = false
    },
    async handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.submitPressed = true
      let toastText = ''
      if (this.newOrganization.id) {
        try {
          await this.$store.dispatch('organizations/updateOrganization', this.newOrganization)
          toastText = `Organisation ${this.name} erfolgreich bearbeitet`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: toastText,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$emit('organisationsUpdated')
          this.$nextTick(() => {
            this.$bvModal.hide('add-organization-modal')
          })
        } catch (e) {
          toastText = `Organisation ${this.name} konnte nicht bearbeitet werden!`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: toastText,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        try {
          await this.$store.dispatch('organizations/addOrganization', this.newOrganization)
          toastText = `Organisation ${this.name} erfolgreich erstellt`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: toastText,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$emit('organisationsUpdated')
          this.$nextTick(() => {
            this.$bvModal.hide('add-organization-modal')
          })
        } catch (e) {
          toastText = `Organisation ${this.name} konnte nicht erstellt werden!`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: toastText,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
